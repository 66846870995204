<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        size="lg"
        content-class="package-modal rounded-14 mx-4"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ interest != null ? $t('settings.interest.update_btn') : $t('settings.interest.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>

        <b-col md="12">
          <div @click="$refs.imageinput.click()">
            <text-input
                id="main-image"
                v-model="form.image"
                readonly
                name="image"
                rules="required"
                :label="$t('settings.currency.form.icon')"
                :placeholder="$t('packages.main_image_placeholder')"
                is-append
                append-class="append-btn"
                :append-text="$t('packages.browse')"
            />
          </div>
          <input
              ref="imageinput"
              type="file"
              accept="image/png, image/jpeg"
              style="display: none"
              @change="uploadImageAction($event)"
          />
        </b-col>

        <b-col md="12">
          <text-input
              id="package-ad"
              v-model="form.name"
              :label="$t('settings.interest.form.name')"
              :placeholder="$t('settings.interest.form.name_placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-10"
            :title="interest!=null ?$t('common.update'):$t('common.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @submitAction="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-10 "
            :title="$t('common.cancel')"
            type="button"
            variant="gray"
            @submitAction="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import {mapActions} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    interest: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        name: '',
        image: ''
      },
      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    interest(newVal) {
      console.log(newVal);
      if (newVal != null) {
        this.form.name = newVal.title;
        this.form.image = newVal.image;
      } else {
        this.form.name = '';
        this.form.image = '';
      }
    },
  },
  created() {
    if (this.interest != null) {
      this.form.name = this.interest.name;
    }
  },
  methods: {
    ...mapActions(['addInterest', 'updateInterest','uploadFiles']),
    submitOrder() {
      if (this.interest == null) {
        this.addInterest(this.form).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form.name = '';
          this.form.image = '';
          this.$store.commit('loadingFinish');
        });
      } else {
        this.updateInterest({
          id: this.interest.id,
          name: this.form.name,
          image: this.form.image,
        }).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form.name = '';
          this.form.image = '';
          this.$store.commit('loadingFinish');
        });
      }
    },
    closeModal() {
      this.show = false;
    },


    uploadImageAction(event, type = 'upload') {
      this.$store.commit('loadingStart', null);
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      // if (this.dropped == 0) files=event;
      // else files=event.currentTarget.files

      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);

        this.uploadFiles(formData).then(response => {
          this.form = {
            ...this.form,
            image: response,
          };
          this.$store.commit('loadingFinish');
        });
      }
    },
  },
};
</script>
