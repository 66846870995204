<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('settings.interest.title')"
      :sub-title="$t('settings.interest.desc')"
      :btn-title="$t('settings.interest.new_btn')"
      :btn="true"
      variant="main"
      @btnAction="showModal = true;interestData=null"
    />

    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <div class="w-table d-flex flex-column settings-interests">
            <div class=" settings-interests-grid">
              <div
                v-for="interest in getAllInterests"
                :key="interest.id"
                class="setting-interest-card"
                @click="showModal = true; interestData = interest"
              >
                <img :src="interest.image" :alt="interest.title" class="interest-img">
                {{ interest.title }}
              </div>
            </div>
            <wameed-no-data
              v-if="getAllInterests && getAllInterests.length < 1"
              icon="nodata-icon"
              :title="$t('packages.no_data')"
              :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-if="getTotalInterests"
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalInterests.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
      <interest-modal
        :visible="showModal"
        :interest="interestData"
        @close="showModal = false"
        @onComplete="()=>loadData()"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import InterestModal from '@/views/pages/settings/interests/components/interestModal';

export default {
  components: {
    InterestModal,
    WameedPagination,
    WameedNoData,
    PageHeader,

  },
  data() {
    return {
      interestData: null,
      showModal: false,
      filterData: {
        page: 1,
        per_page: 20,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllInterests: 'getAllInterests',
      getTotalInterests: 'getTotalInterests',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadInterests: 'loadInterests',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadInterests(this.filterData);
    },

  },
};
</script>
